@import "../../../styles/variables";

.creatableSelect {
  input {
    height: 32px;
  }
}

.select {
  height: 32px;
  input {
    height: inherit;
  }
  path {
    fill: #959595;
  }
  div {
    &:first-letter {
      text-transform: uppercase;
    }
    &[aria-disabled="true"] {
      opacity: 0.5;
    }
  }
}

.selectTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectLabel {
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
  color: $gray300;
}

.errorMessage {
  margin-bottom: 7px;
  color: $red;
  font-size: 12px;
}

.loading {
  width: 16px !important;
  background-color: $white;
  position: absolute !important;
  right: 8px;
}

.creatableLoading {
  width: 16px !important;
  background-color: $white;
  position: absolute !important;
  right: 10px;
}

.optionIcon {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.option {
  width: calc(100% - 20px);
  word-break: break-word;
}

.selectedIcon {
  margin-left: 10px;
  min-width: 14px;
  path {
    fill: $blue;
  }
}

.multiValue {
  path {
    fill: currentColor;
  }
}

.newMultiValue {
  display: inline-block;
}

.noOptionsMessage {
  padding: 10px 20px;
  text-align: center;
}

.singleValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
